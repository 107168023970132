<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <base-select
          :shadow="false"
          :options="filterPackedOptions"
          v-model="filter.is_packed"
          @change="loadOrders"
        />
        <base-input
          type="search"
          :shadow="false"
          placeholder="Cari Kode"
          debounce
          v-model="filter.search"
          @native-input="loadOrders"
        />
      </div>

      <datatable
        cursor
        :total="orders.meta.page.total"
        :perPage="orders.meta.page.perPage"
        :currentPage="orders.meta.page.currentPage"
        :meta="orders.meta"
        :columns="tableColumns"
        @pagechanged="onChangePage"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="order in orders.data"
            :key="order.id"
            :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
            @click="onDetailOrder(getOrder(order))"
          >
            <td :class="classes.td">
              <div class="space-y-1">
                <p class="font-bold text-gray-900">
                  {{ order.attributes.origin_code }}
                </p>
                <p class="text-xs text-gray-500">
                  {{ order.attributes.updatedAt | formatDate }}
                </p>
              </div>
            </td>
            <td :class="classes.td">
              {{ getOrder(order).attributes.item_count | toCurrency }} Produk
            </td>
            <td :class="classes.td">
              {{ getOrder(order).attributes.origin_warehouse_code }}
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge color="indigo">{{
                getOrder(order).attributes.order_status
              }}</base-badge>
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <view-order-modal
      :stock-movement-modal-props="{
        withFirstPacketProduct: true,
        withBatch: true,
      }"
      label="Pembelian"
      readonly
      :with-payment="false"
      :columns="['code', 'warehouse', 'office', 'date', 'user', 'area']"
      :order-detail-columns="[
        'code',
        'packed_qty',
        'unpacked_qty',
        'qty',
        'price',
        'total_price',
      ]"
      :visible="detailModal.visible"
      :order-id="detailModal.orderId"
      @close="detailModal.visible = false"
    >
      <template #actions="{ stockMovements }">
        <base-button
          v-if="!checkFollowUpCreated(stockMovements)"
          :to="{
            name: 'gudang.susulan.barang-dari.supplier.tambah',
            params: { id: detailModal.orderId },
          }"
        >
          <Icon icon="heroicons:truck-solid" class="h-4 w-4 text-white" />
          Buat Susulan
        </base-button>
        <template v-else>
          <base-button color="danger" @click="onDeleteFollowUp(stockMovements)">
            <Icon icon="heroicons:trash-solid" class="h-4 w-4 text-white" />
            Hapus Susulan
          </base-button>
          <base-button @click="onValidateFollowUp(stockMovements)">
            <Icon
              icon="heroicons:shield-check-solid"
              class="h-4 w-4 text-white"
            />
            Validasi Susulan
          </base-button>
        </template>
      </template>
    </view-order-modal>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';
import ViewOrderModal from '@/components/order/view-order-modal.vue';

export default {
  mixins: [requestMixin],
  components: { ViewOrderModal },
  data() {
    return {
      detailModal: {
        visible: false,
        orderId: null,
      },
      filter: {
        is_packed: null,
        search: null,
      },
      loadingOrders: false,
      loadingFollowUp: false,
      orders: {
        meta: {
          page: {},
        },
        data: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    filterPackedOptions() {
      return [
        {
          label: 'Semua',
          value: null,
        },
        { label: 'Selesai', value: true },
        { label: 'Belum Selesai', value: false },
      ];
    },
    loading() {
      return this.loadingOrders || this.loadingFollowUp;
    },
    tableColumns() {
      return [
        {
          name: 'Nomor Pembelian',
        },
        {
          name: 'Susulan',
        },
        {
          name: 'Kode Gudang',
        },
        {
          name: 'Status Susulan',
          theadClass: 'text-center',
        },
      ];
    },
  },
  methods: {
    checkFollowUpCreated(stockMovements) {
      return stockMovements.filter(
        (stockMovement) => !stockMovement.attributes.is_valid_for_shipment
      ).length;
    },
    getOrder(simpleOrder) {
      return this.getSingleIncluded(
        this.orders,
        simpleOrder.relationships.order.data.id
      );
    },
    async loadOrders(params) {
      this.loadingOrders = true;

      const [res, err] = await this.request('/api/v1/orders', {
        params: {
          'filter[destination_warehouse_id]': this.me.current_warehouse,
          // 'filter[is_packed]': this.filter.is_packed,
          'filter[order_status]': 'Ada Susulan',
          'filter[search]': this.filter.search,
          include: 'order',
          'fields[simple-orders]': 'origin_code,updatedAt,order',
          'fields[orders]': 'origin_warehouse_code,item_count,order_status',
          'page[limit]': 5,
          ...params,
        },
      });

      if (!err) {
        this.orders = res;
      }

      this.loadingOrders = false;
    },
    onChangePage(page) {
      this.loadOrders(page);
    },
    async onDetailOrder(order) {
      this.detailModal.orderId = order.id;
      this.detailModal.visible = true;
    },
    async onValidateFollowUp(stockMovements) {
      this.loadingFollowUp = true;

      const followUp = stockMovements.find(
        (stockMovement) => !stockMovement.attributes.is_valid_for_shipment
      );

      const [, err] = await this.request(
        `/api/v1/stock-movements/${followUp.id}/-actions/validate-shipment`,
        {
          method: 'patch',
        }
      );

      if (!err) {
        this.detailModal.visible = false;

        this.loadOrders();
      }

      this.loadingFollowUp = false;
    },
    async onDeleteFollowUp(stockMovements) {
      this.loadingFollowUp = true;

      const followUp = stockMovements.find(
        (stockMovement) => !stockMovement.attributes.is_valid_for_shipment
      );

      const [, err] = await this.request(
        `/api/v1/stock-movements/${followUp.id}`,
        {
          method: 'delete',
        }
      );

      if (!err) {
        this.detailModal.visible = false;

        this.loadOrders();
      }

      this.loadingFollowUp = false;
    },
  },
  created() {
    this.loadOrders();
  },
};
</script>
