var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex justify-end gap-2"},[_c('base-select',{attrs:{"shadow":false,"options":_vm.filterPackedOptions},on:{"change":_vm.loadOrders},model:{value:(_vm.filter.is_packed),callback:function ($$v) {_vm.$set(_vm.filter, "is_packed", $$v)},expression:"filter.is_packed"}}),_c('base-input',{attrs:{"type":"search","shadow":false,"placeholder":"Cari Kode","debounce":""},on:{"native-input":_vm.loadOrders},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('datatable',{attrs:{"cursor":"","total":_vm.orders.meta.page.total,"perPage":_vm.orders.meta.page.perPage,"currentPage":_vm.orders.meta.page.currentPage,"meta":_vm.orders.meta,"columns":_vm.tableColumns},on:{"pagechanged":_vm.onChangePage},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.orders.data),function(order){return _c('tr',{key:order.id,class:[classes.tr, 'cursor-pointer bg-white hover:bg-green-100'],on:{"click":function($event){_vm.onDetailOrder(_vm.getOrder(order))}}},[_c('td',{class:classes.td},[_c('div',{staticClass:"space-y-1"},[_c('p',{staticClass:"font-bold text-gray-900"},[_vm._v(" "+_vm._s(order.attributes.origin_code)+" ")]),_c('p',{staticClass:"text-xs text-gray-500"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(order.attributes.updatedAt))+" ")])])]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getOrder(order).attributes.item_count))+" Produk ")]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm.getOrder(order).attributes.origin_warehouse_code)+" ")]),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{attrs:{"color":"indigo"}},[_vm._v(_vm._s(_vm.getOrder(order).attributes.order_status))])],1)])})}}])})],1),_c('view-order-modal',{attrs:{"stock-movement-modal-props":{
      withFirstPacketProduct: true,
      withBatch: true,
    },"label":"Pembelian","readonly":"","with-payment":false,"columns":['code', 'warehouse', 'office', 'date', 'user', 'area'],"order-detail-columns":[
      'code',
      'packed_qty',
      'unpacked_qty',
      'qty',
      'price',
      'total_price',
    ],"visible":_vm.detailModal.visible,"order-id":_vm.detailModal.orderId},on:{"close":function($event){_vm.detailModal.visible = false}},scopedSlots:_vm._u([{key:"actions",fn:function({ stockMovements }){return [(!_vm.checkFollowUpCreated(stockMovements))?_c('base-button',{attrs:{"to":{
          name: 'gudang.susulan.barang-dari.supplier.tambah',
          params: { id: _vm.detailModal.orderId },
        }}},[_c('Icon',{staticClass:"h-4 w-4 text-white",attrs:{"icon":"heroicons:truck-solid"}}),_vm._v(" Buat Susulan ")],1):[_c('base-button',{attrs:{"color":"danger"},on:{"click":function($event){return _vm.onDeleteFollowUp(stockMovements)}}},[_c('Icon',{staticClass:"h-4 w-4 text-white",attrs:{"icon":"heroicons:trash-solid"}}),_vm._v(" Hapus Susulan ")],1),_c('base-button',{on:{"click":function($event){return _vm.onValidateFollowUp(stockMovements)}}},[_c('Icon',{staticClass:"h-4 w-4 text-white",attrs:{"icon":"heroicons:shield-check-solid"}}),_vm._v(" Validasi Susulan ")],1)]]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }